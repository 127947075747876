import React from "react"
import { Layout, Iframe } from "../components"

const Contact = () => (
  <Layout>
    <Iframe id={process.env.GATSBY_CONTACT_FORM_ID} />
  </Layout>
)

export default Contact
